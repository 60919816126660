import React from 'react'

import Routes from '@routes'
import ReduxProvider from '@store/provider'

import '@styles/global.scss'

const App = () => (
  <ReduxProvider>
    <Routes />
  </ReduxProvider>
)

export default App
