import { Reducer } from 'redux'

import { ContaBasicaState, ContaBasicaTypes } from './types'

const INITIAL_STATE: ContaBasicaState = {
  exemplo: '',
  exemploError: false,
  exemploLoading: false,
}

const reducer: Reducer<ContaBasicaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContaBasicaTypes.EXEMPLO_REQUEST:
      return { ...state, exemploLoading: true, exemploError: false }
    case ContaBasicaTypes.EXEMPLO_SUCCESS:
      return {
        ...state,
        exemploError: false,
        exemploLoading: false,
      }
    case ContaBasicaTypes.EXEMPLO_ERROR:
      return {
        ...state,
        exemploLoading: false,
        exemploError: true,
      }
    default:
      return state
  }
}

export default reducer
