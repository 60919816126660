/**
 * Actions Types
 */
export enum ContaBasicaTypes {
  EXEMPLO_REQUEST = '@contaBasica/EXEMPLO_REQUEST',
  EXEMPLO_SUCCESS = '@contaBasica/EXEMPLO_SUCCESS',
  EXEMPLO_ERROR = '@contaBasica/EXEMPLO_ERROR',
}

/**
 * State Type
 */
export interface ContaBasicaState {
  readonly exemplo?: string
  readonly exemploLoading?: boolean
  readonly exemploError?: boolean
}

/**
 * Action Payloads
 */
export interface ExemploRequestPayload {
  data1: string
}
