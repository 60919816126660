import React from 'react'

import { Provider } from 'react-redux'
import { StateInspector } from 'reinspect'

import { IS_DEVELOPMENT } from '@constants'

import { store } from '.'

const ReduxProvider: React.FC = ({ children }) => (
  <Provider store={store}>
    <Inspector>{children}</Inspector>{' '}
  </Provider>
)

const Inspector: React.FC = ({ children }) =>
  IS_DEVELOPMENT ? <StateInspector name="App">{children}</StateInspector> : <>{children}</>

export default ReduxProvider
