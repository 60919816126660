import { combineReducers, compose, createStore, Store } from 'redux'

import { ApplicationState } from './types'
import contaBasicaReducer from './contaBasica'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const sagaMiddleware = createSagaMiddleware()

const enhancer = composeEnhancers()

export const store: Store<ApplicationState> = createStore(
  combineReducers({ contaBasicaReducer }),
  enhancer,
)
